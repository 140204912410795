.switch_button{
  padding: 0 0.5rem;
}

.logo_image{
  width:100px;
  height:100px;
}

.navbar{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top:1rem;
  padding-bottom:1rem;
  padding-left:2rem;
  padding-right:2rem;
  position: fixed;
  width: 100%;
  min-height:75px;
  /* background-color:white; */
  color:black;
  box-sizing:border-box;
  z-index: 100000;
  font-size:medium;
}

.navbar__links{
  display:flex;
}

.navbar__link{
  padding: 0 0.3rem;
  color: inherit;
}

.navbar__logo{
  font-size: 1.5em;
}


.navbar__burger{
  width:40px;
  height:40px;
  background: transparent;
  color: inherit;
  border: none;
  display: none;
}

.navbar__item::after {
  /* content:"-"; */
  font-size: 2hw;
  /* width:2px;
  height:2px; */
  /* display: flex; */
  /* align-items: center; */
  /* flex-wrap:nowrap; */
  }  

.navbar__item:last-child::after{
  display:none;
}


@media screen and (max-width:767px){

.switch_button {
      padding-top: 16px;
    }
    
.navbar__links{
  flex-direction: column;
  justify-content: center;
  align-items:center;
  position:fixed;

  bottom:0;
  width:0;
  right:-100vw;
  height:100vh;
  background-color: white;
  /* padding:2rem; */
  visibility: hidden;
  transition: all .3s ease-in-out;
}

/* .navbar__item + .navbar__item::after{
  display:none;
} */

.show-nav .navbar__links{
  right:0;
  width:100vw;
  visibility: visible;
}

.navbar__item::after {
content:"";
display: block;
margin:0 auto;
width:3vw;
height: 1px;
background:black;
}

.navbar__item:last-child::after {
  display:none;
}

.navbar__link{
  display:block;
  padding:1rem;
  font-size: 5hw;
}

.navbar__burger{
  display: block;
  position: fixed;
  top:1rem;
  right:1rem;
}

.navbar__burger:hover{
  cursor:pointer;
}
.burger__bar,
.burger__bar::before,
.burger__bar::after{
  display:block;
  width: 40px;
  height: 3px;
  position:relative;
  border-radius:3px;
  background: black;
  transition: all .5s ease-in-out;
}

.burger__bar::before,
.burger__bar::after{
content:"";
position:absolute;
left: 0;
}

.burger__bar::before{
  transform:translateY(-12px);
}

.burger__bar::after{
    transform:translateY(12px);
}

.show-nav .burger__bar::before{
  transform: rotate(45deg);
}

.show-nav .burger__bar::after{
  transform: rotate(-45deg);
}

.show-nav .burger__bar{
  width:0;
  background: transparent;
}

.react-player {
  padding: 0.55rem;
  width: 100%; /* Reset width */
  height: 100%; /* Reset height */
}

.logo_image{
  display:none;
}


}