.AboutMe{
    padding: 11rem 2rem 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.AboutMe_Image{
    padding: 2rem;
    clip-path: circle(34%);
    padding: 2rem 2rem 2rem;
}

.AboutMe_Text{
    max-width: 45rem;
    padding: 2rem 2rem 2rem;
}

@media screen and (max-width:767px) {
    .AboutMe{
        padding: 6rem 4rem 4rem;
        flex-direction: column;
    }
}