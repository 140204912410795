@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@500&display=swap');

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

*{
  margin:0;
  padding:0;
  outline:none;
}

body{
  margin:0;
  font-family: "Nunito",Arial,Helvetica,sans-serif;
  position: relative;
    min-height: 100%;
    box-sizing: border-box;
}

ul{
  list-style:none;
  margin:0;
  padding:0;
}

a, Link{
  text-decoration: none;
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

h1, p{
  margin-bottom: 1rem;
}

html{
  height: 100%;
}

 .main{
  box-sizing:border-box;

   /* min-height: 200vh; */
   /* height: 800rem; */
 }

 /* html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
 } */

