.footer{
    display: flex;
    flex-wrap: wrap;
    flex-flow: column wrap;
    align-items: stretch;
    justify-content: space-between;
    padding:0.5rem;
    width: 100%;
    min-height:55px;
    background-color:black;
    box-sizing:border-box;
    z-index: 100000;
    flex-direction: column;
    position: absolute;
    bottom: 0;
  }

  .button_Wrapper{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-grow: 1;
  }
  
  .footer__link{
    color:white;
  } 
  
  .footer__item{
    align-items: center;
    padding: 1rem;
  }

  .madeby__link{
    padding: 0 0.1rem;
    color:#726f6f;
  }

  .MadeBy_Wrapper{
    color:#726f6f;
    font-size: xx-small;
    display:flex;
    justify-content: flex-end;
    flex-shrink: 1;

  }

  .HeartIcon{
    margin-left:3px;
    margin-right:3px;
    color:#726f6f;
  }

  .Icon{
    color:white;
  }
