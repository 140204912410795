/* .Contact{
    padding: 11rem 20rem 20rem;
} */

.Contact{
    padding: 11rem 2rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.button_Wrapper{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-grow: 1;
  }
  
  .link__link{
    color:black;
  } 
  
  .link__item{
    align-items: center;
    color: white;
    padding: 1rem;
  }

  .button_Wrapper{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-grow: 1;
  }

  .Icon_Contact{
    color:black;
    font-size: 60px;
  }


@media screen and (max-width:767px) {
    .Contact{
        padding: 6rem 4rem 4rem;
    }
}