@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@500&display=swap');

:root {
    --coloneWidth: 247px;
    --scroll: 20;
    --vitesse: 1px;
    --angular_vitesse: 1px;
    --scrollTop: 0px;
    --textOpacity: 1;
}

/* Archives */
  
.archives_wrapper {
      /* width: 60%; */
      width: 90%;
      margin: auto;
      font-size: 0.8rem;
      line-height: 27px;
      margin-bottom: 80px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      /* justify-content: space-between; */
      /* padding: 15rem 1rem 1rem; */
}

.archives_item {
    /* width: calc(100% / 3 - (3 * 20px)); */
    height: calc((var(--coloneWidth) * 350 / 275) + 30px);
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 20px; */
    padding: 40px;
    /* transform: translate3d(0px, 0px, 0px); */
    /* transform: translate3d(0px, calc(var(--scrollTop)*1px), 0px); */
    transform: translate3d(0px, calc(var(--vitesse)*var(--scrollTop)*1px), 0px) rotateZ(calc(((var(--scroll)/150))*var(--angular_vitesse)*10deg));
    /* transition: transform 800ms ease-in 1ms; */
    transition: transform 800ms cubic-bezier(0, 0, 0.43, 0.97) 1ms;
}

.archives_item_wrapper {
    width: 100%;
    line-height: 20px;
}

.archives_item_wrapper_image {

    will-change: transform;
    opacity: 1;
    /* transform: translate3d(0px, 0px, 0px) rotateZ(calc(var(--scroll)/50*var(--test)*10deg)); */
}

.archives_item_image {
    width: var(--coloneWidth);
    /* height: calc(var(--coloneWidth) * 350 / 275); */
    height: var(--coloneWidth);
    object-fit: cover;
    /* box-shadow: 6px 6px 25px rgba(0, 0, 0, 0.5); */
}

.archives_item_image.vertical-image {
    height: calc(var(--coloneWidth) * 350 / 275);
}

.archives_links {
    color: black;
    text-decoration: none;
    font-family: 'Nunito', sans-serif;
}

.archives_item_title {
    margin: 0px;
    font-size: 0.9rem;
    text-transform: uppercase;
    opacity: var(--textOpacity);
    /* transition: opacity 3s ease-out; */
    /* transition-duration: 2s; */
    /* transition: opacity 800ms cubic-bezier(0, 0, 0.43, 0.97) 1ms; */
}

.archives_item_type {
    font-size: 0.5rem;
    text-transform: uppercase;
    line-height: 12px;
    font-weight: 600;
    letter-spacing: 1.6;
    opacity: var(--textOpacity);
    /* transition: opacity 3s ease-out; */
}

@media screen and (max-width:767px) {
    .archives_wrapper {
        width: 60%;
        margin: auto;
        font-size: 0.8rem;
        line-height: 27px;
        margin-bottom: 80px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0rem 1rem 1rem;
  }

    .archives_item {
        width: auto;
        height: auto;
        transform: translate3d(0px, 0px, 0px);
    }

    .archives_item_image {
        width: 247px;
        height: calc(247px * 350 / 275);
        object-fit: cover;
    }    

    .archives_item_image.horizontal-image {
        height: calc(247px * 275 / 350);
    }

    .archives_item_wrapper_image {
        will-change: auto;
        opacity: 1;
        transform: none;
        }
}
