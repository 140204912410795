.filters_wrapper{
    display: flex;
    justify-content: center;
    position: relative;
    padding: 7rem 1rem 1rem;
    transform: translate3d(0px, calc(var(--scrollTop)*1px), 0px);
    transition: transform 800ms cubic-bezier(0, 0, 0.43, 0.97) 1ms;
    /* z-index: 1000000; */
}

.filter_wrapper{
    padding: 20px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.8rem;
    letter-spacing: 0.8px;
}
    
.filter_item{
    background-color: white;
    border: none;
    color: white;
    padding: 10px 17px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    color: black;
    text-decoration: none;
    font-family: 'Nunito', sans-serif;
    border-radius: 12px;
}

.filter_item:hover {
    background-color: #183F35;
    color: white;
    border-radius: 12px;
  }
/* .filter_wrapper::after {
    content: "-";
    font-size: 2hw;
    width: 2px;
    height: 2px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
} */

@media screen and (max-width:767px) {
    .filter_wrapper{
        padding: 0px;
        cursor: pointer;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 0.8rem;
        letter-spacing: 0.8px;
    }
    .filters_wrapper{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        position: relative;
        padding: 4rem 1rem 1rem;
        transform: translate3d(0px, calc(var(--scrollTop)*1px), 0px);
        transition: transform 300ms cubic-bezier(0, 0, 0.43, 0.97) 1ms;
        /* z-index: 1000000; */
    }    
}