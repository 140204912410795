.project_wrapper {
    font-size: 0.8rem;
    line-height: 27px;
    height: auto;
    padding-top: 190px;
    transition: opacity 0.3s ease-in;
}

.project_text {
    width: 60%;
    margin: auto;
    will-change: transform,opacity !important;
}

.project_text_hero {
    display: flex;
    margin-bottom: 40px;
}

.project_text_hero .description {
    width: calc(100% / 3 * 2);
    font-size: 0.9rem;
    line-height: 22px;
    font-weight: 100;
}

.project_text_hero .title {
    width: calc(100% / 3);
    text-transform: uppercase;
    font-size: 1.9rem;
    line-height: 37px;
    font-weight: 600;
}

.reg {
    font-weight: 100;
}

.project_text_infos {
    display: flex;
    margin-bottom: 100px;
}

.project_text_infos .date {
    width: calc(100% / 3);
    font-size: 0.9rem;
    font-weight: 600;
    letter-spacing: 1;
}

.project_text_infos .table_activity {
    width: calc(100% / 3 * 2);
    font-size: 0.7rem;
}

.project_text_infos .table_activity .tr_hero{
    border-bottom: 1px solid black;
    display: flex;
    width: 100%;
    margin-bottom: 8px;
    padding-bottom: 5px;
}

.project_text_infos .table_activity .tr_hero .td_service {
    width: calc(100% / 3 * 2);
    font-weight: 600;
    letter-spacing: 1;
    text-transform: uppercase;
}

.project_text_infos .table_activity .tr {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.project_text_infos .table_activity .tr .td_service {
    width: calc(100% / 3 * 2);
}

.project_text_infos .table_activity .tr .td {
    font-weight: 100;
    font-size: 0.6rem;
    line-height: 20px;
}

.project_images_wrapper{
    /* padding-left:20rem;
    padding-right:20rem; */
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.project_image{
    margin:2rem;
    max-width: 50rem;
}

.project_video{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
}

.project_video_second_container{
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
  }
  .project_video_second_container_vertical {
      position: relative;
      width: 100%;
      padding-top: 177.78%;
      /* (16 / 9) × 100 */
      overflow: hidden;
  }
.project_video_container{
    width: 50rem;
    margin-bottom: 2rem;
  }

  .project_video_container_vertical{
    width: 20rem;
    margin-bottom: 2rem;
  }


@media screen and (max-width:767px) {
    .project_wrapper{
        padding-top: 20%;
    }
    .project_text_hero {
        flex-direction: column;
        margin-bottom: 20px;
    }
    
    .project_text_infos {
        margin-bottom: 0;
        }

    .project_text {
        width: 70%;
    }
    
    .project_image {
        width: 80%;    } 
           
    .project_text_hero .title {
        width: inherit;
    } 
    
    .project_text_hero .description {
        width: inherit;
        padding-top: 1rem;
    } 

    .project_images_wrapper{
        padding-left:0;
        padding-right:0;
    }

    .date{
        display:none;
    }
    
    .project_video_container{
        width:80%;  
        /* margin-left: 5rem; */
    }

    .project_video_container_vertical{
        width: 55%;
        /* margin-bottom: 2rem; */
      }
    
    .project_video {
        /* width: 80%; */
        }

}